<template>
    <template v-if="getAuth">
        <transition name="slide-right" mode="out-in">
            <aside class="aside" v-show="menuIsVisible">
                <div class="aside_logo">
                    <template v-if="cliente != ''">
                        <img :src="require(`./assets/images/logos/${cliente}.png`)" alt="" />
                    </template>
                </div>

                <nav class="aside_nav">
                    <router-link v-if="permissoes.tela_bloqueio" :to="{ name: 'bloqueados' }">Bloqueados</router-link>
                    <router-link v-if="permissoes.tela_usuarios" :to="{ name: 'usuarios' }">Usuários APP</router-link>
                    <router-link v-if="permissoes.tela_gestores" :to="{ name: 'gestores' }">Usuários Gestores</router-link>
                    <router-link v-if="permissoes.tela_lojas" :to="{ name: 'lojas' }">Lojas</router-link>
                    <router-link v-if="permissoes.tela_rebaixas" :to="{ name: 'rebaixas' }">Regras Rebaixas</router-link>
                    <router-link v-if="permissoes.tela_tarefas" :to="{ name: 'tarefas' }">Tarefas</router-link>
                    <router-link v-if="permissoes.tela_tarefas" :to="{ name: 'tarefas.validades' }">Tarefas Sem Validade</router-link>
                    <router-link v-if="permissoes.tela_tarefas" :to="{ name: 'tarefas.blocos' }">Blocos de Tarefas</router-link>
                </nav>

                <button class="aside_leave_btn" @click="leavePage()">Sair</button>

                <button class="menu_button" @click="showMenu()" :class="{ active: menuIsVisible }">
                    <img src="./assets/images/close.svg" alt="menu icon" />
                </button>
            </aside>
        </transition>
    </template>

    <transition name="fade" mode="out-in">
        <button class="menu_button" v-if="!menuIsVisible && getAuth" @click="showMenu()">
            <img src="./assets/images/menu.svg" alt="menu icon" />
        </button>
    </transition>

    <router-view v-slot="slotProps">
        <transition name="fade" mode="out-in">
            <component :is="slotProps.Component"></component>
        </transition>
    </router-view>
</template>

<script>
export default {
    data() {
        return {
            menuIsVisible: false,
            cliente: '',
            permissoes: {}
        };
    },

    computed: {
        getAuth() {
            return this.$store.getters['authenticate/getAuth'];
        },
    },

    watch: {
        $route() {
            if (window.screen.width < 1024) {
                this.menuIsVisible = false;
            }
        },
    },

    created() {
        if (window.screen.width >= 1024) this.menuIsVisible = true;

        const user = JSON.parse(sessionStorage.getItem('user'));
        const permissoes = JSON.parse(sessionStorage.getItem('permissoes'));

        if (user) {
            this.cliente = user.cliente;
            this.$store.dispatch('authenticate/setAuth', true);
        }

        if (permissoes) {
            this.permissoes = permissoes.permissoes;
        }
    },

    beforeUpdate() {
        const user = JSON.parse(sessionStorage.getItem('user'));
        const permissoes = JSON.parse(sessionStorage.getItem('permissoes'));

        if (permissoes) {
            this.permissoes = permissoes.permissoes;
        }

        if (user) {
            this.cliente = user.cliente;
        }        
    },

    mounted() {
        onresize = () => {
            this.menuIsVisible = window.innerWidth >= 1024 ? true : false;
        };
    },

    methods: {
        showMenu() {
            this.menuIsVisible = !this.menuIsVisible;
        },

        leavePage() {
            this.$store.dispatch('authenticate/setAuth', false);
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('permissoes');
            this.$router.push({ name: 'login' });
        },
    },
};
</script>

<style lang="scss">
body {
    background-color: #f9f9f9;
}

#app {
    width: 100%;
    height: 100vh;
    position: relative;
}

.container {
    padding: 30px;

    @media (min-width: 1024px) {
        margin-left: 300px;
        margin-right: 50px;
    }
}

.menu_button {
    padding: 15px;
    border-radius: 50%;
    border: none;
    position: fixed;
    top: 15px;
    left: 15px;
    display: flex;
    box-shadow: 0 0 5px #464646;
    background-color: #fff;
    cursor: pointer;

    &.active {
        left: 220px;
    }

    @media (min-width: 1024px) {
        display: none;
    }
}

.aside {
    position: fixed;
    width: 250px;
    height: 100%;
    background-color: #606060;
    box-shadow: 2px 0 5px #ddd;
    display: grid;
    grid-template-rows: 120px 1fr 50px;

    @media (min-width: 768px) {
        grid-template-rows: 120px 1fr 70px;
    }

    &_logo {
        background-color: #fff;
        text-align: center;
        display: grid;
        place-items: center;

        & img {
            height: 120px;
            min-height: fit-content;
            max-width: 100%;
        }
    }

    &_nav {
        align-self: center;

        & a {
            display: block;
            color: #fff;
            font-size: 1.2rem;
            text-align: center;
            line-height: 50px;
            height: 50px;
            background-color: rgba(255, 255, 255, 0.07);
            transition: 0.2s ease-in-out;

            &:hover,
            &.router-link-active {
                background-color: rgba(255, 255, 255, 0.4);
                text-shadow: 0 0 3px #2c2c2c;
            }

            @media (min-width: 768px) {
                line-height: 60px;
                height: 60px;
            }
        }
    }

    &_leave_btn {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.07);
        border: none;
        color: #fff;
        line-height: 50px;
        font-size: 1.2rem;
        transition: 0.2s ease-in-out;

        &:hover {
            background-color: rgba(255, 255, 255, 0.5);
            text-shadow: 0 0 3px #2c2c2c;
        }

        @media (min-width: 768px) {
            line-height: 70px;
        }
    }
}

.form {
    display: grid;
    gap: 20px;
    font-size: 1.2rem;
    width: 250px;

    @media (min-width: 768px) {
        width: 380px;
    }

    @media (min-width: 1400px) {
        width: 450px;
    }

    div {
        display: flex;
        gap: 15px;
        justify-content: space-between;
        align-items: center;

        &.error {
            label {
                color: #f73b3b;
            }

            input {
                border-color: #f73b3b;
            }
        }
    }

    input,
    select {
        width: 70%;
        padding: 5px 10px;
        font-size: 1.2rem;
        border-radius: 5px;
        border: 1px solid #888;
    }

    input[disabled] {
        background-color: #f0f0f0;
    }

    &_error-msg {
        color: #f73b3b;
    }
}

.slide-right {
    &-enter {
        transform: translate(100%, 0);

        &-from {
            transform: translate(-100%, 0);
        }
        &-to {
            transform: translate(0, 0);
        }
        &-active {
            transition: 0.8s;
        }
    }

    &-leave {
        &-active {
            transition: 0.8s;
        }
        &-to {
            transform: translate(-100%, 0);
        }
    }
}

.fade {
    &-enter {
        &-from {
            opacity: 0;
        }
        &-to {
            opacity: 1;
        }
        &-active {
            transition: 0.3s;
        }
    }

    &-leave {
        &-active {
            transition: 0.3s;
        }
        &-to {
            opacity: 0;
        }
        &-from {
            opacity: 1;
        }
    }
}
</style>
