import './assets/css/reset.scss';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import axios from 'axios';
import VueAxios from 'vue-axios';

const app = createApp(App);

const baseURL = process.env.VUE_APP_BASE_HTTP

app.use(store);
app.use(router);
app.use(
    VueAxios,
    axios.create({
        baseURL,
        headers: { Accept: 'application/json' },
    })
);
app.mount('#app');
