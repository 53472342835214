const bloqueadosStore = {
    namespaced: true,
    state() {
        return {
            produtos: [],
            categoria2Selecionada: '',
            categoria3Selecionada: '',
            produtosFiltrados: []
        };
    },
    mutations: {
        setProdutos(state, payload) {
            state.produtos = payload;
        },
        setProdutosFiltrados(state, payload) {
            state.produtosFiltrados = payload;
        },
        setCategoria2(state, payload) {
            state.categoria2Selecionada = payload;
        },
        setCategoria3(state, payload) {
            state.categoria3Selecionada = payload;
        },
        removeProduto(state, payload) {
            state.produtos = state.produtos.filter((produto) => {
                return produto.codigo_de_barras !== payload;
            });
        },
    },
    actions: {
        setProdutos(context, payload) {
            context.commit('setProdutos', payload);
        },
        setProdutosFiltrados(context, payload) {
            context.commit('setProdutosFiltrados', payload);
        },
        setCategoria2(context, payload) {
            context.commit('setCategoria2', payload);
        },
        setCategoria3(context, payload) {
            context.commit('setCategoria3', payload);
        },
        removeProduto(context, payload) {
            context.commit('removeProduto', payload);
        },
    },
    getters: {
        getProdutos(state) {
            return state.produtos;
        },
        getProdutosFiltrados(state) {
            return state.produtosFiltrados;
        },
        getCategorias2(state) {
            const categorias = state.produtos.map((produto) => {
                return produto.categoria_2;
            });

            return [...new Set(categorias)];
        },
        getCategorias3(state) {
            const filtrado = state.produtos.filter((produto) => {
                return produto.categoria_2.toString().includes(state.categoria2Selecionada);
            });

            const categorias = filtrado.map((produto) => {
                return produto.categoria_3;
            });

            return [...new Set(categorias)];
        },
        getMarcas(state) {
            const filtrado = state.produtos.filter((produto) => {
                return (
                    produto.categoria_2.toString().includes(state.categoria2Selecionada) &&
                    produto.categoria_3.toString().includes(state.categoria3Selecionada)
                );
            });

            const marcas = filtrado.map((produto) => {
                return produto.marca;
            });

            return [...new Set(marcas)];
        },
    },
};

export default bloqueadosStore;
