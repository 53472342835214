const authenticateStore = {
    namespaced: true,
    state() {
        return {
            isAuthenticated: false,
        };
    },
    mutations: {
        setAuth(state, payload) {
            state.isAuthenticated = payload;
        },        
    },
    actions: {
        setAuth(context, payload) {
            context.commit('setAuth', payload);
        },
    },
    getters: {
        getAuth(state) {
            return state.isAuthenticated;
        },
    },
};

export default authenticateStore;