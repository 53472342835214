<template>
    <div class="login">
        <div class="login_form">
            <form action="" @submit.prevent="logarConta()">
                <div class="login_fields" :class="{ error: loginIsInvalid }">
                    <label class="login_label" for="nome">Usuário</label>
                    <input type="text" id="nome" name="nome" v-model="nome" />
                </div>

                <div class="login_fields" :class="{ error: loginIsInvalid }">
                    <label class="login_label" for="senha">Senha</label>
                    <input type="password" id="senha" name="senha" v-model="senha" />
                </div>

                <p v-if="loginIsInvalid">Usuário ou senha inválidos!</p>

                <div class="login_button">
                    <button type="submit">Acessar</button>
                </div>
            </form>

            <div class="login_logo">
                <img src="../assets/images/logo.webp" alt="logo minus" />
            </div>
        </div>

        <div class="login_bg">
            <h1>Bem vindo ao site de gerenciamento</h1>
            <p>Entre com o usuário para acessar sua conta</p>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        getAuth() {
            return this.$store.getters['authenticate/getAuth'];
        },
    },

    data() {
        return {
            nome: '',
            senha: '',
            loginIsInvalid: false,
        };
    },

    watch: {
        nome() {
            this.loginIsInvalid = false;
        },
        senha() {
            this.loginIsInvalid = false;
        },
    },

    methods: {
        logarConta() {
            const data = {
                nome: this.nome,
                senha: this.senha,
            };

            this.axios
                .post('/login', data)
                .then((res) => {
                    const usuario = res.data;

                    sessionStorage.setItem(
                        'user',
                        JSON.stringify({
                            isLogged: true,
                            cliente: usuario.cliente,
                            token: usuario.token,
                        })
                    );

                    sessionStorage.setItem(
                        'permissoes',
                        JSON.stringify({
                            permissoes: usuario.permissoes,
                        })
                    );

                    const timer = setTimeout(() => {
                        this.$store.dispatch('authenticate/setAuth', true);
                        clearTimeout(timer);
                    }, 500);

                    this.$router.push({ name: 'usuarios' });
                })
                .catch(() => {
                    this.loginIsInvalid = true;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.login {
    position: relative;
    height: 70%;
    min-height: 450px;
    margin: 0 5%;
    border-radius: 10px;
    box-shadow: 0 0 8px #adadad;
    top: 50%;
    transform: translateY(-50%);
    display: grid;

    @media (min-width: 768px) {
        grid-template-columns: 35% 65%;
    }

    &_form {
        position: relative;
        background-color: #fff;
        border-radius: 10px;

        & form {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: grid;
            gap: 35px;
            width: 90%;

            @media (min-width: 1200px) {
                width: 70%;
            }
        }

        p {
            font-size: 1.1rem;
            color: #f72a2a;
        }
    }

    &_button {
        text-align: right;

        & button {
            padding: 10px;
            background-color: #28a741;
            color: #fff;
            cursor: pointer;
            font-size: 1.4rem;
            border: none;
            border-radius: 10px;
            width: 100%;
            transition: 0.2s ease-in-out;

            &:hover {
                background-color: #266131;
            }

            @media (min-width: 1024px) {
                width: 200px;
            }
        }
    }

    &_fields {
        color: #8f8f8f;

        label {
            font-size: 1.5rem;
            margin-bottom: 5px;
            display: inline-block;
        }

        input {
            width: 100%;
            border: none;
            height: 30px;
            border-bottom: 1px solid #8f8f8f;
            font-size: 1.2rem;

            &:focus {
                color: #28a741;
                border-bottom: 1px solid #28a741;
            }
        }

        &:focus-within {
            .login_label {
                color: #28a741;
            }
        }

        &.error {
            label {
                color: #f72a2a;
            }

            input {
                border-color: #f72a2a;
            }

            &:focus-within {
                .login_label {
                    color: #f72a2a;
                }
            }
        }
    }

    &_logo {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
    }

    &_bg {
        color: #fff;
        position: relative;
        padding-left: 20px;
        padding-top: 50px;
        display: none;
        font-family: cursive;

        &::after {
            content: '';
            background: linear-gradient(120deg, #174e22 0%, #105c27 35%, #184618 100%);
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: -1;
        }

        h1 {
            font-size: 1.5rem;
            margin-bottom: 20px;
        }

        p {
            letter-spacing: 0.5px;
            font-size: 1rem;
        }

        @media (min-width: 768px) {
            padding-left: 60px;
            padding-top: 150px;
            display: block;

            h1 {
                font-size: 2rem;
            }

            p {
                font-size: 1.2rem;
            }
        }

        @media (min-width: 1200px) {
            padding-left: 90px;

            h1 {
                font-size: 3.2rem;
            }

            p {
                font-size: 1.6rem;
            }
        }
    }
}
</style>
